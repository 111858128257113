/* globals Bounce, gtag */
$(function() {

    jQuery.fn.selectText = function () {
        var doc = document;
        var element = this[0];
        var range, selection;
        // console.log(this, element);
        if (doc.body.createTextRange) {
            range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            selection = window.getSelection();
            range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

    // scroll the header
    var hovered = false;

    $('.topic .player iframe').hover(function() {
        hovered = true;
    }, function() {
        hovered = false;
    });

    $(window).blur(function() {
        if (hovered && $('html, body').scrollTop() <= $('.topic-header').height()) {
            $('html, body').animate({
                scrollTop: $('.topic-header').height()
            }, 300);
        }
    });

    function videoElement(data) {
        // return `<a href="topic.html#${data.slug}" class="video">
        //             <div class="week-number">${data.week}</div>
        //             <div class="thumbnail">
        //                 <div class="video-shadow"></div>
        //                 <figure>
        //                     <img src="${data.asset_url}" alt="thumbnail">
        //                     <div class="play-button">
        //                         <img src="images/play.svg" alt="play">
        //                     </div>
        //                 </figure>
        //             </div>

        //             <div class="date">${data.month} ${data.day} ${data.year}</div>

        //             <h1><span>${data.title}</span></h1>
        //             <p>${data.excerpt}</p>
        //         </a>`;

        return `<a href="${data.url}" class="video">
                    <div class="week-number">${data.week}</div>
                    <div class="thumbnail">
                        <div class="video-shadow"></div>
                        <figure>
                            <img src="${data.asset_url}" alt="thumbnail">
                            ${data.fixed_link ? '' : `<div class="play-button">
                                <img src="images/play.svg" alt="play">
                            </div>`}
                        </figure>
                    </div>

                    <div class="date">${data.month} ${data.day} ${data.year}</div>

                    <h1><span>${data.title}</span></h1>
                    <p>${data.excerpt}</p>
                </a>`;
        
    }

    function relatedElement(data) {
        console.log(data);

        var image = data.asset_url ? `<img src="${data.asset_url}" alt="link-image">` : '';
        var icon = data.asset_url ? `<figcaption class="icon-${data.template}"></figcaption>` : `<div class="icon icon-${data.template}"></div>`;

        return `<li>
            <a href="${data.source_link ? data.source_link : data.source_asset_url}" target="_BLANK">
                <figure>
                    ${image}
                    ${icon}
                </figure>
                
                <div class="title">
                    <h5>${data.title}</h5>
                </div>
            </a>
        </li>`;
    }

    // get the stories
    if ($('.topics').length) {
        $.ajax({
            type: 'POST',
            url: 'https://cms.tishreiinthemoment.com/api/70-years-development/stories',
            data: {
                '_method': 'GET',
                'api_key': 'b2f32f488c18a85ae2c63ec449ea0f256bdd6b578888fe5c14bbede64e6b1e40',
                'direction': 'DESC'
            },
            dataType: 'json',
            success: function (data) {
                if(data.project) {
                    $('html head title').text(data.project.name);
                    $('[data-input="project_description"]').html(data.project.description);
                }

                if(data.project.intro) {
                    $('.intro').addClass('has-notice-message');
                    $('.notice-message').html(`<div class="wrapper">${data.project.intro}</div>`);
                }

                if(!data.project.intro) {
                    if (data.stories.length) {
                        var firstVideo = data.stories.shift();
                        $('.current-video').html(videoElement(firstVideo));

                        console.log(firstVideo);
                        console.log(data.stories);
                    }
                }

                if(data.project.title) {
                    $('.topics').before(`<div class="project-title">
                        <div class="wrapper">
                            <h4>${data.project.title}</h4>
                        </div>
                    </div>`);
                }

                $('.topics .wrapper').html('');
                $.each(data.stories, function(index, story) {
                    $('.topics .wrapper').append(videoElement(story));
                });
            },
            error: function (request) {
                console.log(request.responseText);
            }
        });
    }

    // get the story
    if($('.topic').length) {
        var hash = window.location.hash;
        hash = hash.substr(1, hash.length);
        console.log(hash);
        if(!hash) {
            window.location.href = 'index.html';
            return false;
        }

        $.ajax({
            type: 'POST',
            url: 'https://cms.tishreiinthemoment.com/api/70-years-development/'+hash,
            data: {
                '_method': 'GET',
                'api_key': 'b2f32f488c18a85ae2c63ec449ea0f256bdd6b578888fe5c14bbede64e6b1e40'
            },
            dataType: 'json',
            success: function (data) {
                console.log(data);
                if (data.story) {
                    $('html head title').text(data.story.title);

                    $('html head').append(`
                    <meta property="og:title" content="${data.story.title}">
                    <meta property="og:description" content="${data.story.description}">
                    <meta property="og:image" content="${data.story.asset_url}">
                    `);

                    $('[data-input="story_embed_url"]').attr('src', data.story.embed_url);
                    $('[data-input="story_description"]').html(data.story.description);
                    $('[data-input="story_week"]').html(data.story.week);
                    $('[data-input="story_month"]').html(data.story.month);
                    $('[data-input="story_day"]').html(data.story.day);
                    $('[data-input="story_year"]').html(data.story.year);
                    $('[data-input="story_title"]').html(data.story.title);
                    $('[data-input="story_description"]').html(data.story.description);
                    $('[data-input="story_facebook"]').attr('href', data.story.social.facebook);
                    $('[data-input="story_twitter"]').attr('href', data.story.social.twitter);
                    $('[data-input="story_whatsapp"]').attr('href', data.story.social.whatsapp);
                    $('[data-input="story_link"]').attr('href', data.story.social.url);

                    // push the event
                    gtag('event', 'Load Page', {
                        'event_category': 'Story',
                        'event_label': data.story.title
                    });

                    // set the page title
                    // ga.push(["_set", "title", data.story.title+' TEST']);
                    // ga.push(["_trackPageview"]);

                    // ga('send', 'pageview', {
                    //     'page': window.location.href,
                    //     'title': data.story.title+' TEST'
                    // });
                }

                if (!data.slides.length) {
                    $('.topic-links').hide();
                }

                if (data.slides.length) {
                    $('.topic-links ul').html('');
                    $.each(data.slides, function(index, slide) {
                        $('.topic-links ul').append(relatedElement(slide));
                    });
                }
            },
            error: function (request) {
                console.log(request.responseText);
            }
        });
    }

    $('body').on('click', '.share', function(e) {
        e.stopPropagation();

        var $this = $(this);

        gtag('event', 'Click '+$this.attr('data-method'), {
            'event_category': 'Share Topic',
            'event_label':  $('[data-input="story_title"]').text()
        });
    });

    $('body').on('click', '.social-link', function(e) {
        e.stopPropagation();

        var $this = $(this);

        gtag('event', 'Click '+$this.attr('data-method'), {
            'event_category': 'Footer Social Link'
            // 'event_label':  $('[data-input="story_title"]').text()
        });
    });


    $('body').on('click', '.copy-link', function () {
        var $this = $(this);
        var element = $('<div>', {
            html: $this.attr('href')
        });
        $('body').append(element);

        // $this.siblings('.link').selectText();
        element.selectText();
        document.execCommand("Copy", false, null);
        window.getSelection().removeAllRanges();
        element.remove();

        $('.toast p').html('Link Copied');
        TweenMax.set($('.toast'), {
            autoAlpha: 1,
            y: '-0%'
        });
        TweenMax.to($('.toast'), 0.4, {
            autoAlpha: 1,
            y: function() {
                return -$('.toast').height() - 30
            },
            ease: Bounce.easeOut
        });
        setTimeout(() => {
            TweenMax.to($('.toast'), 0.4, {
                autoAlpha: 0
            });
        }, 3000);

        return false;
    });
});